<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת תרגול ליחידת הלימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן שם לתרגול">
                        <b-input v-model="model.name"></b-input>
                    </b-field>
                    <b-field label="יחידת לימוד">
                        <FieldInlineSelect label="" :filterable="true" :clearable="true" :optionsAsync="getLessons"
                            v-model="model.lessonId" />
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import LessonService from "@/services/LessonService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";

export default {
    name: "DialogAddExercise",
    props: ["store", "lessonId"],
    components: {
        FieldInlineSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
    },
    created() {
        if (this.lessonId) this.model.lessonId = this.lessonId;
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                name: null,
                lessonId: null,
            }
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            if (this.lessonId) this.model.lessonId = +this.model.lessonId.Value;
            LessonService.addExercise(this.courseId, {
                Name: this.model.name,
                LessonId: this.model.lessonId,
                status: true
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.$emit('customEvent');
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        getLessons() {
            return () =>
                CoursesService.getCourse(this.courseId, {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    SortedBy: "id",
                    ResultType: 3
                }, true).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
    },
};
</script>

<style scoped lang="scss"></style>
